import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext } from 'react';
import {
  TranscriptMsg,
  CaptionShard,
  CaptionsSource,
  SimpleShard,
} from 'shared/hooks/axon/gateway.types';

const checkIfIsFuser = (source: CaptionsSource) => source === 'FUSER';

export const useUpdateTranscript = () => {
  const { captionShards, detailedShards } = useContext(LandingPageContext);

  const updateTranscript = ({ shards, source }: TranscriptMsg) => {
    if (!checkIfIsFuser(source)) {
      return;
    }
    try{
    if (!detailedShards.current) {
        Object.entries(shards as unknown as SimpleShard).forEach(([id, shardText]) => {
          const shard: CaptionShard = {
            shardId: Number(id),
            shardText,
            alternatives: [],
            editable: true,
            shardWords: [],
          };
          captionShards!.current[Number(id)] = shard;
        });

        return;
      }

      Object.values(shards).forEach(({ shardId, shardText, shardWords }) => {
        const shard: CaptionShard = {
          shardId: shardId,
          shardText: shardText,
          alternatives: shardWords['0'] ? shardWords[0].wordAlternatives : [],
          editable: true,
          shardWords,
        };

        captionShards!.current[Number(shardId)] = shard;
      });

    } catch(error: any) {
      console.error('updateTranscript failed with error ', error.message, '/nstack: ', error.stack)
      console.error('updateTranscript failed with shards ', JSON.stringify(shards))
    }
  };

  return {
    updateTranscript,
  };
};