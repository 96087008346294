import BottomControls from 'modules/ipcts-call-session/components/bottom-controls/bottom-controls.component';
import { Chronometer } from 'modules/ipcts-call-session/components/chronometer/chronometer.component';
import CallControls from 'modules/ipcts-call-session/components/controls/call-controls.component';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { config } from 'shared/config/cts.config';
import * as React from 'react';
import {
  ContentEditableEvent,
  Editor,
  EditorProvider,
} from 'shared/components/ui/editor';
import {
  ESilentCallDisposition,
  ETerminationReasonCode,
} from 'shared/datasources/call-detail-record-service/entities.dto';
import { CaptionShard, GateWaySockets } from 'shared/hooks/axon/gateway.types';

import * as Styled from './ipcts-session.styled';

interface IIpctsProps {
  text: CaptionShard[];
  isActiveSession: boolean;
  isTokenReady: boolean;
  cardCaptionCallRef: React.MutableRefObject<HTMLDivElement | null> | null;
  handleFocus: (event: any) => void;
  handleBlur: () => void;
  handleKeyDown: (event: any) => void;
  handleKeyup: (event: any) => void;
  handleChange: (event: ContentEditableEvent) => void;
  handleClick: (event: any) => void;
  handleStartListening: (socket: GateWaySockets) => void;
  setIsCallActive: (b: boolean) => void;
  dispositionCallEnd: () => void;
  handoffCallEnd: () => void;
  addCustomCaption: (text: string, editable?: boolean) => void;
  dispositionSelected: ETerminationReasonCode;
  setDispositionSelected: React.Dispatch<
    React.SetStateAction<ETerminationReasonCode>
  >;
  setSilentDispositionSelected: React.Dispatch<
    React.SetStateAction<ESilentCallDisposition>
  >;
}

export const IpctsSession: React.FunctionComponent<IIpctsProps> = ({
  text,
  isActiveSession,
  isTokenReady,
  cardCaptionCallRef,
  handleFocus,
  handleBlur,
  handleKeyDown,
  handleKeyup,
  handleChange,
  handleClick,
  handleStartListening,
  setIsCallActive,
  dispositionCallEnd,
  handoffCallEnd,
  addCustomCaption,
  dispositionSelected,
  setDispositionSelected,
  setSilentDispositionSelected,
}) => {
  const {
    captionAreaFontSize,
    captionAreaLineHeight,
    captionAreaFontFamily,
  } = React.useContext(IPCTSSessionContext);

  const textArea = React.useRef<HTMLElement>();
  const startedListening = React.useRef(false)

  React.useEffect(() => {
    if(!startedListening.current){
      handleStartListening(GateWaySockets.captionerSocket);
      startedListening.current = true;
    }
    return () => {
      if(config.BUILD_TYPE === "production"){
        setIsCallActive(false);
      }
    };
  }, []);

  return (
    <Styled.DataContainer>
      <Styled.LeftContainer>
        <Styled.CardSessionContainer>
          <Styled.CardCaptionCall
            ref={cardCaptionCallRef}
            className="vertical-default-scroll"
            isDisabled={isActiveSession && !isTokenReady}
            fontSize={captionAreaFontSize}
            lineHeight={captionAreaLineHeight}
            fontFamily={captionAreaFontFamily}
          >
            <div className="editor-provider-wrapper">
              <EditorProvider>
                <Editor
                  id="captionsWindow"
                  value={text}
                  onFocus={handleFocus}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyup}
                  onChange={handleChange}
                  onClick={handleClick}                  
                  disabled={isActiveSession && !isTokenReady}
                  editorRef={textArea}
                  isCTS
                  handleBlur={handleBlur}
                />
              </EditorProvider>
            </div>
          </Styled.CardCaptionCall>
        </Styled.CardSessionContainer>
        <BottomControls />
      </Styled.LeftContainer>
      <Styled.RightContainer>
        <Styled.ChronoContainer>
          <Chronometer />
        </Styled.ChronoContainer>
        <CallControls
          dispositionCallEnd={dispositionCallEnd}
          handoffCallEnd={handoffCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
        />
      </Styled.RightContainer>
    </Styled.DataContainer>
  );
};
