import {  Popover } from 'antd';
import { useEffect, useState } from 'react';
import { CorrectionsPopUp } from 'shared/components/ui/editor/editor/CorrectionsPopUp';
import { PShard } from 'shared/components/ui/editor/editor/CorrectionsPopUp.styled';

import { CaptionShard } from 'shared/hooks/axon/gateway.types';

const LINE_BREAK = '\n'
interface ShardParagraphProps extends CaptionShard {
  onFocus:  (event: any) => void;
}

export const ShardParagraph = ({
  onFocus,
  shardText,
  shardId,
  alternatives,
  editable,
  shardWords
}: ShardParagraphProps) => {
  const [showPopup, setShowPopup] = useState(false)
  const [isVisible, setIsVisible] = useState(false);
  const [text, setText] = useState<string>(shardText);
  const [isOnCorrection, setIsOnCorrection] = useState(false);

  useEffect(() => {
    setText(shardText);
  }, [shardText]);

  function blurShard(event: any){
    event.preventDefault();
    event.stopPropagation();
  }
  function handleContextMenu(event: any) {
    setShowPopup(true)
    setIsVisible(true)
    event.preventDefault();
    event.stopPropagation();
  }
  return (
    <>
      {showPopup ? (
        <Popover
        key={`shard-${shardId}-popup`}
        id={`shard-${shardId}-popup`}
        content={
          <CorrectionsPopUp
            shardId={shardId}
            text={text.replace(/^[\W_]+|[\W_]+$/g, '').trim()}
            alternatives={alternatives}
            onHide={() => setIsVisible(false)}
            isOpen={isVisible}
            setIsOnCorrection={setIsOnCorrection}
          />
        }
        trigger={'click'}
        visible={isVisible}
        onVisibleChange={() => setIsVisible(!isVisible)}
      >
        <PShard
          isOnCorrection={isOnCorrection}
          key={`shard-${shardId}`}
          id={`shard-${shardId}`}
          data-alternatives={JSON.stringify(alternatives)}
          className="item"
          data-id={shardId.toString()}
          onCopy={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText('');
          }}
          onCut={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText('');
          }}
          onPaste={(e) => false}
          onDragStart={(e) => false}
          data-text={text}
        >
          {renderLineBreakText(text)}
        </PShard>
      </Popover>
    ) : (
        <PShard
          tabIndex={0}
          isOnCorrection={isOnCorrection}
          key={`shard-${shardId}`}
          id={`shard-${shardId}`}
          data-alternatives={JSON.stringify(alternatives)}
          className="item"
          data-id={shardId.toString()}
          onBlur={blurShard}
          onFocus={onFocus}
          // onContextMenu={handleContextMenu}
          onCopy={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText('');
          }}
          onCut={(e) => {
            e.preventDefault();
            navigator.clipboard.writeText('');
          }}
          onPaste={(e) => false}
          onDragStart={(e) => false}
          data-text={text}
          data-type="shard"
        >
          {renderLineBreakText(text)}
        </PShard>
      )}
    </>
  );
};

function renderLineBreakText(text: string){
  const linebreakIndex = text.indexOf(LINE_BREAK)
  if(linebreakIndex !== -1){
    const displayText = text.split(LINE_BREAK);
    const lastTextPart = displayText.length-1;
    return displayText.map((textPart:string, index) => {
      if(index < lastTextPart){
        return (
          <>
            {textPart}
            <br />
          </>
        )
      } else {
        return textPart
      }
    })
  } else {
    return text
  }
}