import {
  IPCTSSessionContextData,
  Caption,
} from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.types';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import { ContentEditableEvent } from 'react-simple-wysiwyg';
import { GateWaySockets } from 'shared/hooks/axon/gateway.types';
import useGateWay from 'shared/hooks/axon/ipcts-gw/use-gateway.hook';
import usePFCGateWay from 'shared/hooks/axon/ipcts-pfc-gw/use-pfc-gateway.hook';
import {
  LogLevelType,
  usePostDynatraceLogs,
} from 'shared/hooks/dynatrace/use-dynatrace';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';
import { demoLog } from 'shared/utils/demo-log';

export type Transcription = {
  text: string;
  shardId: number;
  alternatives: string[];
};

export type SessionType = {
  id: number;
  token: string;
  title: string;
  description: string;
  enableTranscript: boolean;
  sessionStart: string;
  sessionStop: string;
  createdDt: string;
  sessionPassword: string;
};
const LINE_BREAK = '\n'

export const IPCTSSessionContext = React.createContext<IPCTSSessionContextData>(
  {
    textForPhone: '',
    text: [],
    handleStopListening: () => Promise.resolve(),
    handleFocus: () => {},
    handleBlur: () => {},
    handleKeyDown: () => {},
    handleKeyup: () => {},
    handleChange: () => {},
    handleClick: () => {},
    handleStartListening: () => {},
    cardCaptionCallRef: null,
    isActiveSession: false,
    isTokenReady: false,
    customWord: [],
    setCustomWord: () => {},
    reset: () => {},
    addCustomCaption: () => {},
    correctionsCounter: 0,
    captionAreaFontSize: 24,
    setCaptionAreaFontSize: () => {},
    captionAreaLineHeight: 1,
    setCaptionAreaLineHeight: () => {},
    captionAreaFontFamily: '',
    setCaptionAreaFontFamily: () => {},
    accent: '',
    setAccent: () => {},
    isMuted: false,
    setIsMuted: () => {},
    handleElementChange: () => {},
    sendShardCorrection: () => {},
    sendShardInsertion: () => {},
    activeGateway: '',
    setActiveGateway: () => {},
  }
);
interface CustomSelection extends Partial<Selection> {
  anchorOffset: number;
}
const IPCTSSessionProvider = ({ children }: { children: React.ReactNode }) => {
  const { captionShards, agentId, callId, setCallId } =
    React.useContext(LandingPageContext);
  const cardCaptionCallRef = React.useRef<HTMLDivElement | null>(null);

  const elements = React.useRef<Caption[]>([]);
  const [focusedShard, setFocusedShard] = React.useState<any | null>(null);
  const [textForPhone, setTextForPhone] = React.useState('');
  const [isActiveSession, setIsActiveSession] = React.useState(false);
  const [isTokenReady, setIsTokenReady] = React.useState(false);
  const [accent, setAccent] = React.useState('');
  const [ activeGateway, setActiveGateway ] = React.useState('')

  const [isMuted, setIsMuted] = React.useState(false);
  const [customWord, setCustomWord] = React.useState<any>([]);
  const correctionsCounterRef = React.useRef(0);
  const [captionAreaFontSize, setCaptionAreaFontSize] = React.useState(24);
  const [captionAreaLineHeight, setCaptionAreaLineHeight] = React.useState(32);
  const [captionAreaFontFamily, setCaptionAreaFontFamily] = React.useState('');
  const { log } = usePostDynatraceLogs();

  const {
    openPFCConnection,
    closePFCConnections,
    finishPFCCorrections,
    sendPFCCorrection,
  } = usePFCGateWay({ setCallId, captionShards });

  const {
    openConnection,
    closeConnections,
    sendCorrection,
    sendInsertion,
  } = useGateWay();

  const sendShardCorrection = (
    socket: GateWaySockets,
    shardId: number,
    newText: string
  ) => {
    newText = removeZeroWidthSpace(newText);
    if (activeGateway === GateWaySockets.captionerSocket) {
      sendCorrection(shardId, newText);
    } else {
      sendPFCCorrection(shardId, newText);
    }
  };

  const sendShardInsertion = (
    priorWordId: number,
    priorWord: string,
    newText: string,
  ) => {
    if (activeGateway === GateWaySockets.captionerSocket) {
      sendInsertion(priorWordId, priorWord, newText);
    }
  };

  const reset = () => {
    setIsMuted(false);
    elements.current = [];
  };
  const removeCorrectionSpans = function(shardHtml:string){
    let shardText = shardHtml.replaceAll('<span class="correction">', '<c>');
    shardText = shardText.replaceAll('</span>', '</c>');
    return shardText;
  }
  const removeZeroWidthSpace = function (text: string){
    return text.replaceAll(zeroWidthJoiner.space, '');
  }
  const removeZeroWidthJoiner = React.useCallback((textToParse: string) => {
    let count = 1;
    textToParse = textToParse.replace(zeroWidthJoiner.space, '');
    return textToParse.split('').reduce((finalText, letter) => {
      const isEven = (count + 1) % 2 === 0;
      const isC = letter.search(zeroWidthJoiner.unicode);
      if (isC === -1) {
        return `${finalText}${letter}`;
      }
      count++;
      return isEven ? `${finalText}<c>` : `${finalText}</c>`;
    }, '');
  }, []);

  const handleElementChange = (
    element: HTMLElement,
    event: ContentEditableEvent
  ) => {};

  const handleFocus = (event: any) => {
    const element = event.target;
    if(element.attributes?.['data-type']?.value === 'shard'){
      setNewShardFocus(element)
    } else {
      setFocusedShard(null)
    }
  }
  const handleBlur = () => {
    setNewShardFocus(null)
  }
  function setCaretPosition(element: any, childNodeIndex: number, position: number) {
    const selectedRange = document.createRange();
    selectedRange.setStart(element.childNodes[childNodeIndex], position);
    selectedRange.collapse(true);
    const selection = window.getSelection();
    selection!.removeAllRanges();
    selection!.addRange(selectedRange);
  }
  function setNewShardFocus(newShard: any){
    if(focusedShard){
      const originalValue = focusedShard?.attributes?.['data-text']?.value;
      if(originalValue !== focusedShard.innerText){
        let shardId = focusedShard.attributes?.['data-id']?.value;
        if(!isNaN(shardId)){
          shardId = parseInt(shardId, 10)
        }
        demoLog(`** handleFocus sendShardCorrection ${focusedShard.id} ${focusedShard.innerText}`)
        sendShardCorrection(GateWaySockets.captionerSocket, shardId, focusedShard.innerText)
      }
    }
    demoLog(`setFocusedShard to ${newShard?.id} from ${focusedShard?.id}`);
    setFocusedShard(newShard)
  }
  function handleArrowLeft(element: any){
    let selectionElement = focusedShard?.previousElementSibling;
    if(element === focusedShard.parentNode
    && selectionElement?.attributes?.['data-type']?.value === 'shard'){
      let lastChildNodeIndex = selectionElement.childNodes.length-1;
      if(selectionElement.childNodes[lastChildNodeIndex].nodeType !== 3){
        // get last node of child
        selectionElement = selectionElement.childNodes[lastChildNodeIndex];
        lastChildNodeIndex = selectionElement.childNodes.length-1;
      }
      const position = selectionElement.childNodes[lastChildNodeIndex].length;
      setCaretPosition(selectionElement, lastChildNodeIndex, position)
      setNewShardFocus(focusedShard?.previousElementSibling)
    }
  }
  function handleArrowRight(element: any){
    let selectionElement = focusedShard?.nextElementSibling;
    if(element === focusedShard.parentNode
    && selectionElement?.attributes?.['data-type']?.value === 'shard'){
      let firstChildNodeIndex = 0;
      if(selectionElement.childNodes[firstChildNodeIndex].nodeType !== 3){
        selectionElement = selectionElement.childNodes[firstChildNodeIndex];
        firstChildNodeIndex = 0;
      }
      const position = 1;
      setCaretPosition(selectionElement, firstChildNodeIndex, position)
      setNewShardFocus(focusedShard?.nextElementSibling)
    }
  }
  function handleArrowUpDown(element: any){
    if(element === focusedShard.parentNode){
      // find shard? next to right?
      const goToShard = element?.nextElementSibling ? element?.nextElementSibling : element?.previousElementSibling;
      if(goToShard){
        setNewShardFocus(goToShard)
      }
    } else if(element !== focusedShard
    && element?.attributes?.['data-type']?.value === 'shard'
    ){
      setNewShardFocus(element)
    }
  }
  const handleKeyDown = (event: any) => {
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    switch(event.key){
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowLeft':
      case 'ArrowRight':
        break;
      case 'Enter':
        event.preventDefault();
        event.stopPropagation();
      break;
      default:
        if(element.attributes?.['data-type']?.value !== 'shard'){
          event.preventDefault();
          event.stopPropagation();
        }
        break;
    }
  };
  const handleKeyup  = (event: any) => {
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    let shardId = element.attributes?.['data-id']?.value;
    if(!isNaN(shardId)){
      shardId = parseInt(shardId, 10)
    }
    switch(event.key){
      case 'ArrowUp':
      case 'ArrowDown':
        handleArrowUpDown(element)
        break;
      case 'ArrowLeft':
        handleArrowLeft(element)
        break;
      case 'ArrowRight':
        // if element is focusedShard.parentNode then we want to move to next sibling
        handleArrowRight(element);
        break;
    }
    if(element.attributes?.['data-type']?.value === 'shard'
    && event.key === 'Enter'
    ){
      const originalValue = element.attributes?.['data-text']?.value;
      if(!originalValue){
        console.error('handleKeyup failed to get original shard text for element ', element);
        return;
      }

      if(event.ctrlKey){
        const cursor: CustomSelection | null = window.getSelection();
        const cursorIndex = cursor?.anchorOffset;
        const text = element.innerText;
        const shardStart = text.substring(0, cursorIndex)
        const shardEnd = text.substring(cursorIndex)
        const newText = shardStart + LINE_BREAK + shardEnd
        sendShardCorrection(GateWaySockets.captionerSocket, shardId, newText)
        demoLog('** handleKeyup send linebreak ShardCorrection ', element)
        return;
      }
      if(originalValue === element.innerText){
        // nothing to do
        return;
      }
      demoLog(`** handleKeyup sendShardCorrection ${shardId} ${element.innerText}`)
      sendShardCorrection(GateWaySockets.captionerSocket, shardId, element.innerText)
    }
  };
  const handleClick  = (event: any) => {
    const element = (window as any)?.getSelection()?.anchorNode?.parentNode;
    demoLog('** handleClick for element ', element)

  };
  const handleChange = (event: ContentEditableEvent) => {};

  const handleStartListening = async (socket: GateWaySockets) => {
    log({
      logLevelType: LogLevelType.INFO,
      callId,
      agentNumber: agentId,
      methodName: handleStartListening.name,
      parameters: { socket },
    });
    if (socket === GateWaySockets.PFCSocket) {
      openPFCConnection();
      setIsTokenReady(true);
    } else {
      await openConnection();
      setIsTokenReady(true);
    }

    setIsActiveSession(true);
  };

  const handleStopListening = React.useCallback(
    async (socket: GateWaySockets) => {
      if (socket === GateWaySockets.PFCSocket) {
        finishPFCCorrections();
        await closePFCConnections();
      } else {
        closeConnections();
      }
      return Promise.resolve();
    },
    [setIsActiveSession]
  );

  const addCustomCaption = (text: string, editable: boolean = true) => {
    captionShards.current.push({
      shardText: text,
      shardId: -1,
      alternatives: [],
      editable: editable,
      shardWords: [],
    });
    setTextForPhone(text);
  };

  (window as any).addCustomCaption = (text: string, editable:boolean = true) => {
    addCustomCaption(text, editable);
  };

  React.useEffect(() => {
    const unloadCallback = (e: BeforeUnloadEvent) => {
      const event = e;

      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <IPCTSSessionContext.Provider
      value={{
        textForPhone,
        text: captionShards.current,
        isActiveSession,
        isTokenReady,
        cardCaptionCallRef,
        customWord,
        setCustomWord,
        handleStopListening,
        handleFocus,
        handleBlur,
        handleKeyDown,
        handleKeyup,
        handleChange,
        handleClick,
        handleStartListening,
        reset,
        addCustomCaption,
        correctionsCounter: correctionsCounterRef.current,
        captionAreaFontSize,
        setCaptionAreaFontSize,
        captionAreaLineHeight,
        setCaptionAreaLineHeight,
        captionAreaFontFamily,
        setCaptionAreaFontFamily,
        accent,
        setAccent,
        isMuted,
        setIsMuted,
        handleElementChange,
        sendShardCorrection,
        sendShardInsertion,
        activeGateway,
        setActiveGateway,
      }}
    >
      {children}
    </IPCTSSessionContext.Provider>
  );
};

export default IPCTSSessionProvider;
