import * as React from 'react';
import { useEffect } from 'react';
import { ContentEditable, ContentEditableProps } from './ContentEditable';
import { useEditorState } from './EditorContext';
import '../styles.css';

export function Editor({ children, onSelect, handleBlur, ...rest }: EditorProps) {
  const containerRef = React.useRef<any>();

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  });

  function onClickOutside(event: MouseEvent) {
    if (event.target === containerRef.current) {
      return;
    }

    if (containerRef.current!.contains(event.target as HTMLElement)) {
      return;
    }
    if(handleBlur){
      handleBlur();
    }

  }
  return (
    <div ref={containerRef} className="rsw-editor" tabIndex={0} >
      {children}
      <ContentEditable 
        id={'rsw-cts-editor'} 
        className="rsw-cts" 
        {...rest}
      />
    </div>
  );
}

export interface EditorProps extends ContentEditableProps {
  handleBlur?: () => void;
}
